<template>
	<div>
		<div class="progression-header-container">
			<h2>Progression</h2>
			<p class="fs25 font-weight-bold mb-3 mt-3 text-right" style="color: #34e5c8">
				{{ score }} <img class="iconsBigRules" src="@/assets/icons/lightening-green.svg" /> / {{ totalScore }}
			</p>
		</div>

		<v-progress-linear
			:value="(score / totalScore) * 100"
			color="#34e5c8"
			height="10"
			rounded
			background-color="#F1F1F1"
		/>
	</div>
</template>

<script>
export default {
	name: 'CategorySessionPointProgressBar',
	props: {
		score: Number,
		totalScore: Number,
	},
};
</script>

<style lang="scss" scoped>
.progression-header-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.iconsBigRules {
	width: 18px;
}
</style>
